import events from 'eventslibjs'

export default function (dom, opts) {
  var options = {
    headerSel: '.header__container',
    openCls: 'header__container--active',
    htmlOpenCls: 'nav-open',
    navMain: '.nav-main__nav',
    subNav: '.nav-main__subnav',
    openTrigger: '[data-navigation-toggle]',
    closeTrigger: '[data-navigation-close]',
    subnavSel: '.nav-main__single',
    subnavCls: 'nav-main__single--open',
    // TODO: this is a complicated selector, there must be another way
    subnavTrigger: '.level_1 > .nav-main__single > a, .level_1 > .nav-main__single > span',
    subnavCloseTrigger: '[data-navigation-subnav-close]',

    ...opts
  }

  init()

  function init() {
    events.on('click', options.openTrigger, onOpen)
    events.on('click', options.subnavTrigger, onSubnavOpen)
    events.on('click', options.subnavCloseTrigger, onSubnavClose)
  }

  function onOpen() {
    if (!getNavigationContainer().classList.contains(options.openCls)) {
      getNavigationContainer().classList.add(options.openCls)
      getHtml().classList.add(options.htmlOpenCls)
      getNavigationToggle().setAttribute('aria-expanded', true)
      events.on('touchend.outer', document, onOuterClick)
    } else {
      onClose()
    }
  }

  function onClose() {
    // toggle
    getNavigationContainer().classList.remove(options.openCls)
    getHtml().classList.remove(options.htmlOpenCls)
    getNavigationToggle().setAttribute('aria-expanded', false)
    scrollToTop()
    onSubnavClose()
    events.off('touchend.outer', document, onOuterClick)
  }

  function onOuterClick(e) {
    if (!getNavigationContainer().contains(e.target)) {
      onClose()
    }
  }

  function onSubnavOpen(e) {
    const nav = e.target.parentElement.parentElement

    if (nav && !nav.classList.contains(options.subnavCls)) {
      getSubNavigationRelative(nav).scrollTop = 0
      e.preventDefault()
      nav.classList.add(options.subnavCls)
    } else {
      onSubnavClose(nav, e.target)
    }
  }

  function onSubnavClose() {
    dom.querySelectorAll('.' + options.subnavCls).forEach(node => {
      node.classList.remove(options.subnavCls)
    })
  }

  function getNavigationContainer() {
    return options.headerSel === ""
      ? dom
      : dom.querySelector(options.headerSel)
  }

  function getNavigation() {
    return dom.querySelector(options.navMain)
  }

  function getSubNavigationRelative(parent) {
    return parent.querySelector(options.subNav)
  }

  function getNavigationToggle() {
    return dom.querySelector(options.openTrigger)
  }

  function getHtml() {
    return document.documentElement
  }

  function scrollToTop() {
    getNavigation().scrollTop = 0
  }
}
